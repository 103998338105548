import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface ICampaignsState {
    checkedCampaigns: string[]
}

@Module({ dynamic: true, store, name: 'campaigns', namespaced: true })
class Campaigns extends VuexModule implements ICampaignsState {
    public checkedCampaigns: string[] = []

    @Mutation
    public SET_CHECKED_CAMPAIGNS (checkedCampaigns: string[]) {
        this.checkedCampaigns = checkedCampaigns
    }
}

export const CampaignsModule = getModule(Campaigns)
