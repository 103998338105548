







import { Component, Prop, Vue } from 'vue-property-decorator'

export enum VsFixedTopActionsVariantEnum {
    'full-width' = 'full-width',
    'narrow' = 'narrow',
}

export enum VsFixedTopActionsMarginEnum {
    'auto' = 'auto',
    'none' = 'none',
}

export enum VsFixedTopActionsPositionEnum {
    'relative' = 'relative',
    'fixed' = 'fixed',
    'sticky' = 'sticky',
}

export enum VsFixedTopActionsElevationEnum {
    'light-small' = 'light-small',
    'light-medium' = 'light-medium',
    'light-large' = 'light-large',
}

@Component({
    name: 'VsFixedTopActions',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: VsFixedTopActionsMarginEnum.auto,
    }) margin?: VsFixedTopActionsMarginEnum

    @Prop({
        required: false,
        default: VsFixedTopActionsVariantEnum['full-width'],
    }) variant?: VsFixedTopActionsVariantEnum

    @Prop({
        required: false,
        default: VsFixedTopActionsPositionEnum.fixed,
    }) position?: VsFixedTopActionsPositionEnum

    @Prop({
        required: false,
        default: true,
        type: Boolean,
    }) sidebar?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) noPadding?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) noXPadding?: boolean

    @Prop({
        required: false,
        default: '',
    }) actionsStickyDistance?: string

    @Prop({
        required: false,
        default: VsFixedTopActionsElevationEnum['light-medium'],
    }) elevation?: VsFixedTopActionsElevationEnum

    get dynamicStyles () {
        return {
            'max-width': this.maxWidth,
        }
    }

    get dynamicActionsContainerClasses () {
        return {
            'vs-actions-fixed-container': true,
            'vs-sticky': this.position === VsFixedTopActionsPositionEnum.sticky,
            'vs-fixed': !this.position || this.position === VsFixedTopActionsPositionEnum.fixed,
            'vs-top-0': true,
            'vs-left-0': !this.position || this.position === VsFixedTopActionsPositionEnum.fixed,
            'vs-right-0': !this.position || this.position === VsFixedTopActionsPositionEnum.fixed,
            'vs-left-sidebar-distance': this.sidebar && this.position === VsFixedTopActionsPositionEnum.fixed,
            'vs-shadow-light-small': this.elevation === VsFixedTopActionsElevationEnum['light-small'],
            'vs-shadow-light-medium': this.elevation === VsFixedTopActionsElevationEnum['light-medium'],
            'vs-shadow-light-large': this.elevation === VsFixedTopActionsElevationEnum['light-large'],
        }
    }

    get dynamicActionsClasses () {
        return {
            'vs-fixed-top-actions': true,
            'vs-mx-auto': VsFixedTopActionsMarginEnum.auto,
            'vs-box-content': true,
            'vs-px-4': !this.noPadding && !this.noXPadding,
            'md:vs-px-12': !this.noPadding && !this.noXPadding,
            'xl:vs-px-16': !this.noPadding && !this.noXPadding,
            'vs-actions-fixed': true,
            'vs-py-4': true,
            'vs-bg-white': true,
            'vs-border-grey-100': true,
        }
    }

    get maxWidth () {
        if (this.margin === VsFixedTopActionsMarginEnum.none) return 'none'
        if (this.variant === VsFixedTopActionsVariantEnum.narrow) return '744px'
        return '100%'
    }
}
