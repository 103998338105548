























































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsCreateTagModal from '@/components/VsCreateTagModal/Index.vue'

import {
    assignEntityLabelToEntities,
} from '@/api/consoleApi/entityLabels'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsAssignCampaignLabelTagModal',
    components: {
        VsCreateTagModal,
    },
})
export default class extends Vue {
    private campaignIds: number[] = []

    @Prop({
        default: () => [],
        required: false,
    }) labelTags!: any[]

    private show = false
    private loading = false
    private selectedTag = ''
    $refs: any

    private async assignTag () {
        this.loading = true
        try {
            await assignEntityLabelToEntities(this.selectedTag, 'campaigns', { ids: this.campaignIds })
            this.$root.$vsToast({
                heading: this.$t('campaigns.index.addTagSuccess'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })

            this.$emit('tag-assigned')
            this.show = false
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    public openModal (campaignIds: number[]) {
        this.campaignIds = campaignIds
        this.selectedTag = ''
        this.show = true
    }
}
