






















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { CampaignsModule } from '@/store/modules/campaigns'
import VsFixedTopActions from '@/components/VsFixedTopActions/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsTopMultiActions',
    components: {
        VsFixedTopActions,
        VsSectionHeader,
        VsDropdownButton,
    },
})
export default class extends Vue {
    private show = true
    $refs: any

    get checkedCampaigns () {
        return CampaignsModule.checkedCampaigns
    }

    set checkedCampaigns (val) {
        CampaignsModule.SET_CHECKED_CAMPAIGNS(val)
    }

    @Watch('checkedCampaigns', { immediate: true, deep: true })
    private toggleVsTopMultiActions (val: any[]) {
        if (val.length > 0) {
            this.openVsTopMultiActions()
        } else {
            this.closeVsTopMultiActions()
        }
    }

    private openVsTopMultiActions () {
        this.show = true
    }

    private closeVsTopMultiActions () {
        this.show = false
    }

    private closeAndClear () {
        this.checkedCampaigns = []
    }
}
