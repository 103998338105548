
















































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { getEntityLabels } from '@/api/consoleApi/entityLabels'
import { generateArchiveUrl } from '@/api/console'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'

@Component({
    name: 'VsCampaignArchiveModal',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsLoader,
    },
})
export default class extends Vue {
    private open = false
    private loading = false
    $refs: any
    labelTags: any[] = []
    tagId = ''
    templateUrl = ''
    tag: any = null
    archiveLink = ''
    showAdvanced = false

    public openModal () {
        this.tag = null
        this.tagId = ''
        this.showAdvanced = false
        this.archiveLink = ''
        this.templateUrl = ''
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    async getLabelTags (search: string) {
        try {
            const resp = await getEntityLabels({
                page: 1,
                search: 'name:' + search,
                limit: 1000,
            })
            this.labelTags = resp.data.data
        } catch (e) {
            this.labelTags = []
            console.log(e)
        }
    }

    setTag (e: any) {
        this.tag = this.labelTags.find(el => el.id === e)
    }

    formatBadgeLabel (e: any) {
        return this.tag?.name || e
    }

    async generateArchiveUrl () {
        const valid = await this.$refs.archiveForm.validate()

        if (!valid) return
        try {
            const resp = await generateArchiveUrl({
                entity_label_id: this.tagId,
                template_url: this.templateUrl,
            })

            this.archiveLink = resp.data.archive_url
        } catch (e) {
            const statusCode = get(e, 'status', 0)
            let message = ''
            if (statusCode === 403) {
                message = 'Non hai i permessi per generare il link dell\'archivio'
            }
            if (statusCode === 404) {
                message = 'Profilazione non trovata'
            }
            this.$root.$vsToast({
                heading: 'Errore durante la generazione del link',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private copyToClipboard (string: string) {
        try {
            copyToClipboard(string)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.linkCopied'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.linkCopiedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }
}
