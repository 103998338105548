







































import { Component, Vue } from 'vue-property-decorator'
import { createEntityLabel } from '@/api/consoleApi/entityLabels'

@Component({
    name: 'VsCreateTagModal',
})
export default class extends Vue {
    private show = false
    private tagName = ''
    private loading = false
    $refs: any

    public openModal () {
        this.tagName = ''
        this.show = true
    }

    private async createTag () {
        this.loading = true
        try {
            const resp = await createEntityLabel({
                name: this.tagName,
            })
            const tagId = resp.data
            this.$emit('tag-created', tagId)
            this.show = false
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }
}
