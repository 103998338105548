





































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    createModelFromEmail,
} from '@/api/consoleApi/contents/templates'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsSaveModelModal',
})
export default class extends Vue {
    @Prop({
        default: () => [],
        required: false,
    }) tags!: any[]

    private show = false
    private loading = false
    private messageId = ''
    private title = ''
    private description = ''
    $refs: any

    private async createModel () {
        const isValid = await this.$refs.createModalForm.validate()
        if (!isValid) return
        this.loading = true
        const data = {
            email_id: this.messageId,
            title: this.title,
            description: this.description,
        }
        try {
            await createModelFromEmail(data)
            this.$root.$vsToast({
                heading: this.$t('campaigns.saveModelModal.success'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('model-created')
            this.show = false
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.saveModelModal.error'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    public openModal (messageId: any) {
        this.messageId = messageId
        this.title = ''
        this.description = ''
        this.show = true
    }
}
