









































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsFilterContainer',
})
export default class extends Vue {
    @Prop({ default: '', required: false }) countFilters!: number
    @Prop({ default: '', required: false }) totalFound?: string

    private open = false

    public closeModal () {
        this.open = false
    }

    public async openModal () {
        this.open = true
    }
}
